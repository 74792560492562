var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "ma-0",
      attrs: { "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("span", { class: _vm.fontClass }, [
                    _vm._v(_vm._s(_vm.value))
                  ]),
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          disabled: !_vm.showEditIcon,
                          small: "",
                          icon: ""
                        }
                      },
                      on
                    ),
                    [_c("v-icon", { attrs: { small: "" } }, [_vm._v("edit")])],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.editDialog,
        callback: function($$v) {
          _vm.editDialog = $$v
        },
        expression: "editDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center title" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("insert_drive_file")
                ]),
                _c("span", { staticClass: "primary--text text-uppercase" }, [
                  _vm._v(
                    "update " +
                      _vm._s(_vm.editAttrFullName ? _vm.editAttrFullName : "")
                  )
                ])
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c("span", { staticClass: "font-weight-bold mt-3" }, [
                  _vm._v("Old " + _vm._s(_vm.editAttrFullName) + ":")
                ]),
                _vm._v(" " + _vm._s(_vm.value) + " "),
                _c("span", { staticClass: "font-weight-bold mt-3" }, [
                  _vm._v("New " + _vm._s(_vm.editAttrFullName) + ":")
                ]),
                _c("v-textarea", {
                  attrs: { rows: "2", "auto-grow": "", "persistent-hint": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            "Provide a new " + _vm._s(_vm.editAttrFullName)
                          )
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.newVal,
                    callback: function($$v) {
                      _vm.newVal = $$v
                    },
                    expression: "newVal"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      _vm.editDialog = false
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "error" },
                  on: { click: _vm.updateValue }
                },
                [_vm._v("Update")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }