<template>
    <v-dialog class="ma-0" v-model="editDialog" max-width="500">
        <template v-slot:activator="{ on }">
            <div class="d-flex align-center">
                <span :class="fontClass">{{ value }}</span>
                <v-btn :disabled="!showEditIcon" v-on="on" small icon><v-icon small>edit</v-icon></v-btn>
            </div>
        </template>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center title">
                    <v-icon class="mr-1">insert_drive_file</v-icon>
                    <span class="primary--text text-uppercase">update {{ editAttrFullName ? editAttrFullName : '' }}</span>
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold mt-3">Old {{ editAttrFullName }}:</span>
                    {{ value }}
                    <span class="font-weight-bold mt-3">New {{ editAttrFullName }}:</span>
                    <v-textarea rows="2" auto-grow persistent-hint v-model="newVal">
                        <template v-slot:label>Provide a new {{ editAttrFullName }}</template>
                    </v-textarea>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="primary" class="mr-1" @click="editDialog = false">Cancel</v-btn>
                <v-btn text color="error" @click="updateValue">Update</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { truncateText } from '@/utils'

export default {
    name: 'EditableArea',
    data() {
        return {
            newVal: this.$props.value,
            editDialog: false
        }
    },
    props: {
        value: String,
        apiURL: String,
        editAttr: String,
        editAttrFullName: String,
        showEditIcon: { type: Boolean, default: true },
        fontClass: { type: String, default: 'subtitle-2 primary--text' },
        id: Number,
        successMessage: String,
        finally: Function,
        defaultValue: { type: String, default: 'Not available' },
        color: { type: String, default: 'primary' }
    },
    methods: {
        updateValue: function() {
            const patchBody = {
                [this.$props.editAttr]: this.$data.newVal
            }
            this.$axios
                .patch(this.$props.apiURL, patchBody)
                .then(response => {
                    this.$props.finally()
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: this.$props.successMessage,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .finally(() => {
                    this.$data.editableMode = !this.$data.editableMode
                    this.$data.editDialog = false
                })
        },
        truncateText: function(text, maxLength) {
            return truncateText(text, maxLength)
        }
    },
    watch: {
        value: function(nextValue, prevValue) {
            this.$data.newVal = nextValue
        }
    }
}
</script>
